export function ellipsisText(text: string, numberOfWords: number = 15) {
  const content = text.split(' ').slice(0, numberOfWords).join(' ')
  const lastLetter = content[content.length - 1]

  if (/[^A-Za-z0-9]/.test(lastLetter)) {
    return `${content.slice(0, content.length - 1)}...`
  }

  return `${content}...`
}

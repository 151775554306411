import Link from 'next/link'
import { useRouter } from 'next/router'
import cx from 'classnames'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import { useUser } from '../context/Auth'

const LockIcon = () => {
  return (
    <svg
      className="ml-2 h-[14px] w-[14px]"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
    >
      <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" />
    </svg>
  )
}

const ArticleFilters = () => {
  const router = useRouter()
  const user = useUser({ redirectTo: '/login' })
  const inactive = 'bg-transparent text-black border-dashed hover:border-solid'
  const active = 'bg-black text-white'
  const locked = 'opacity-50 hover:border-dashed cursor-not-allowed'

  if (!user) return null

  function isActive(market: string) {
    if (router.pathname === '/categories/[slug]') {
      if (market === 'all') return true
    }
    if (router.pathname === '/categories/[slug]/[market]') {
      return router.query.market === market
    }
    if (router.pathname === '/markets/[slug]') {
      return router.query.slug === market
    }
    if (router.pathname === '/') {
      if (market === 'north-america')
        return (
          user?.permissions['articles-na'].access &&
          !user?.permissions['articles-emea'].access
        )
      if (market === 'emea')
        return (
          user?.permissions['articles-emea'].access &&
          !user?.permissions['articles-na'].access
        )
      if (market === 'all') return true
    }
    return false
  }

  function getHref(market: string) {
    if (router.pathname === '/categories/[slug]') {
      if (market === 'all') return `/categories/${router.query.slug}`
      return `/categories/${router.query.slug}/${market}`
    }
    if (router.pathname === '/categories/[slug]/[market]') {
      if (market === 'all') return `/categories/${router.query.slug}`
      return `/categories/${router.query.slug}/${market}`
    }
    if (market === 'all') return '/'
    return `/markets/${market}`
  }

  return (
    <div className="flex items-center justify-center pb-20">
      {user.permissions['articles-na'].access &&
        user.permissions['articles-emea'].access && (
          <Link href={getHref('all')}>
            <a className={cx('button', isActive('all') ? active : inactive)}>
              All Markets
            </a>
          </Link>
        )}

      {user.permissions['articles-na'].access ? (
        <Link href={getHref('north-america')}>
          <a
            className={cx(
              'na',
              'button',
              isActive('north-america') ? active : inactive
            )}
          >
            North America
          </a>
        </Link>
      ) : (
        <>
          <button className={cx('na', 'button', inactive, locked)}>
            North America
            <LockIcon />
          </button>
          <ReactTooltip
            id="na"
            place="bottom"
            anchorSelect=".na"
            content="This market is not included in your license."
            className="tooltip"
          />
        </>
      )}

      {user.permissions['articles-emea'].access ? (
        <Link href={getHref('emea')}>
          <a
            className={cx(
              'emea',
              'button',
              isActive('emea') ? active : inactive
            )}
          >
            EMEA
          </a>
        </Link>
      ) : (
        <>
          <button className={cx('emea', 'button', inactive, locked)}>
            EMEA
            <LockIcon />
          </button>
          <ReactTooltip
            id="emea"
            place="bottom"
            anchorSelect=".emea"
            content="This market is not included in your license."
            className="tooltip"
          />
        </>
      )}

      <style jsx>{`
        .button {
          @apply mx-2 flex items-center justify-center whitespace-nowrap border border-black px-4 py-2 text-center font-ibm text-sm font-semibold transition-all duration-500 ease-in-out;
          word-break: break-word;
          height: auto;
          white-space: pre-wrap;
          min-width: 100px;
          height: 58px;
        }

        @screen md {
          .button {
            @apply min-w-[160px] px-6;
            height: auto;
          }
        }
      `}</style>
    </div>
  )
}

export default ArticleFilters

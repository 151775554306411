import Link from 'next/link'
import { NewArticle } from '../types'
import ExclusiveTag from './ExclusiveTag'

type CategorySectionProps = {
  name: string
  slug: string
  news: NewArticle[]
}

function CategorySection(props: CategorySectionProps) {
  const { name, news, slug } = props
  return (
    <div className="flex flex-col">
      <div className="category-container">
        <span
          className="uppercase"
          dangerouslySetInnerHTML={{ __html: name }}
        />
        <Link href={`/categories/${slug}`}>
          <a className="transition-opacity duration-300 ease-in-out hover:opacity-75">
            More stories -{'>'}
          </a>
        </Link>
      </div>
      <div className="grid flex-1 grid-cols-1 gap-4 md:mt-[-19px] md:grid-cols-3 md:gap-12">
        {news?.slice(0, 3)?.map((n, index) => (
          <div
            key={`category-${name}-item-${index}`}
            style={{
              paddingTop: n.type === 'exclusive' ? 0 : 31,
            }}
          >
            {n.type === 'exclusive' && <ExclusiveTag />}
            <Link href={`/articles/${n.slug}`}>
              <a className="new-title block hover:underline">{n.title}</a>
            </Link>
          </div>
        ))}
      </div>
      <style jsx>{`
        .category-container {
          @apply mb-4 flex justify-between font-ibm text-xs text-black hover:text-black-700 md:mb-8;
          border-bottom: 1px dashed #000000;
        }
        .new-title {
          @apply font-inter text-xl font-bold;
        }
      `}</style>
    </div>
  )
}

export default CategorySection

import cx from 'classnames'
import { ellipsisText } from '../utils/text'

type MainDescriptionProps = {
  className?: string
  content: string
  ellipsis?: boolean
}

function MainDescription(props: MainDescriptionProps) {
  const { content, ellipsis, className } = props
  const parsedContent = ellipsis ? ellipsisText(content, 30) : content

  return (
    <>
      <div
        className={cx('description', className)}
        dangerouslySetInnerHTML={{ __html: parsedContent }}
      />
      <style jsx>{`
        .description {
          @apply mt-8 font-inter text-xl font-normal text-black-800;
        }

        .description :global(p) {
          @apply mb-[20px] block;
        }
        .description :global(a) {
          @apply underline;
        }

        .description :global(a:hover) {
          @apply no-underline;
        }
      `}</style>
    </>
  )
}

export default MainDescription

import cx from 'classnames'
import css from 'styled-jsx/css'

import { NewArticle } from '../types'
import MainDescription from './Description'
import ExclusiveTag from './ExclusiveTag'
import NewDate from './NewDate'
import Title from './Title'

type MainNewProps = NewArticle & {
  className?: string
}

function MainNew(props: MainNewProps) {
  const { title, description, slug, date, type, markets, className } = props
  const isExclusive = type === 'exclusive'

  return (
    <div className={cx('flex flex-col items-center', className)}>
      {isExclusive && <ExclusiveTag />}
      <NewDate
        date={date}
        className={cx(
          'main-date',
          !isExclusive && 'no-exclusive-margin',
          components.className
        )}
      />
      <Title
        title={title}
        className="text-center hover:underline"
        slug={slug}
      />
      <MainDescription content={description} className="text-center" />
      {components.styles}
    </div>
  )
}

const components = css.resolve`
  .main-date {
    @apply mb-2;
  }

  .no-exclusive-margin {
    margin-top: 27px;
  }
`

export default MainNew
